import {
    Redirect,
    Route, Switch, useLocation, useRouteMatch,
} from "react-router-dom";

import { useMe } from "../providers/Me"
import { useQuery, gql, useMutation } from "@apollo/client"
import { Loading, MyTextField, AppBarButton, MyLink } from "./Widgets"
import { useState, useEffect } from "react"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';
import DownloadIcon from '@mui/icons-material/Download';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
    Stack,
    Container,
    Card,
    CardContent,
    TablePagination,
    Typography,
    Box,
    Tab,
    Tabs,
    Button,
    Checkbox,
    Link,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Alert,
    InputAdornment,
    IconButton,
    Paper,
    Badge,
    AppBar,
    Toolbar,
} from '@mui/material'
import { useParams, useHistory } from "react-router-dom"
import { HIDDEN_ATTRIBUTES, Settings } from '../Settings'




const CreateJsonReportComponent = ({ ssid, onChange }) => {
    const [excludeGermline, setExcludeGermline] = useState(false);
    const [skipValidation, setSkipValidation] = useState(true);
    const [skippedSamples, setSkippedSamples] = useState(['ctdna']);

    // for debugging purposes:
    const [showQuery, setShowQuery] = useState(false);

    const [createJsonReport, { data, loading, error }] = useMutation(gql`
        mutation CreateJsonReportMutation($input: GenerateJsonFromOncodnaFilesMutationInputType!) {
            generateJsonFromOncodnaFiles(input: $input) {
                runtimeSeconds
            }
        }
    `)

    const toggleSkippedSample = (sample) => {
        if (skippedSamples.includes(sample)) {
            setSkippedSamples(skippedSamples.filter(s => s !== sample));
        } else {
            setSkippedSamples([...skippedSamples, sample]);
        }
    }

    const input = {
        ssid,
        excludeGermline,
        skipValidation,
        // convert to csv:
        skippedSamples: skippedSamples.join(','),
    }

    return (
        <>
            <FormGroup>
                <FormControlLabel control={<Checkbox checked={excludeGermline} onChange={(e) => setExcludeGermline(e.target.checked)} />} label="Exclude Germline" />
                <FormControlLabel control={<Checkbox checked={skipValidation} onChange={(e) => setSkipValidation(e.target.checked)} />} label="Skip Validation" />
                {['primary', 'meta', 'blood', 'ctdna'].map(sample => (
                        <FormControlLabel 
                                key={sample}
                                control={<Checkbox checked={skippedSamples.includes(sample)}
                                onChange={() => toggleSkippedSample(sample)} />}
                                label={`Skip ${sample} sample`} 
                        />
                ))}
            </FormGroup>
            <pre>{JSON.stringify(input, null, 2)}</pre>

            {error && <Alert severity="error"><pre>{JSON.stringify(error)}</pre></Alert>}

            {data && (
                <Alert severity="success">
                    <Link>
                        Auto JSON generated (runtime: {data.generateJsonFromOncodnaFiles.runtimeSeconds} seconds)
                    </Link>
                </Alert>
            )}

            <Button
                onClick={async () => {
                    await createJsonReport({ variables: { input } })
                    onChange && onChange();
                }}
                variant="outlined"
                disabled={loading}
                color="primary"
                startIcon={loading ? <Loading /> : <DownloadIcon />}
            >
                Create JSON Report
            </Button>
        </>
    )
}


const CreatePdfReportComponent = ({ ssid, onChange }) => {
    const input = {
        ssid,
    }
    const [createJsonReport, { data, loading, error }] = useMutation(gql`
        mutation CreatePdfReportMutation($input: GeneratePatientReportMutationInputType!) {
            generatePatientReport(input: $input) {
                url
                runtimeSeconds
            }
        }
    `)
    return (
        <>
            {error && <Alert severity="error">{error.message}</Alert>}
            {data && (
                <Alert severity="success">
                    <Link>
                        Download PDF report generated (runtime: {data.generatePatientReport.runtimeSeconds} seconds)
                    </Link>
                </Alert>
            )}
            <Button
                onClick={() => {
                    createJsonReport({ variables: { input } })
                    onChange && onChange();
                }}
                variant="outlined"
                disabled={loading}
                color="primary"
                startIcon={loading ? <Loading /> : <DownloadIcon />}
            >
                Create PDF Report
            </Button>
        </>
    )
}




export const PatientReport = () => {
    const { me } = useMe()
    // const { ssid } = useParams()

    const [ssid, setSsid] = useState('2228')

    const history = useHistory()
    const { data, loading, error, refetch } = useQuery(gql`
        query GetPatientReport($ssid: String!) {
            patientReports(
                ssid: $ssid
            ) {
                patient {
                        id
                }
                reportAuto {
                        pdfUrl
                        jsonUrl
                }
                reportMab {
                        pdfUrl
                        jsonUrl
                }
            }
        }
    `, {
        variables: { ssid },
    })
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <FormGroup>
                        <MyTextField
                                label="SSID"
                                autoFocus
                                value={ssid}
                                onChange={(e) => setSsid(e.target.value)}
                                InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                                <IconButton onClick={() => history.push(`/patient/${ssid}/summary`)}>
                                                <LaunchIcon />
                                                </IconButton>
                                        </InputAdornment>
                                        ),
                                }}
                        />
                    </FormGroup>

                    {error && <Alert severity="error">{error.message}</Alert>}

                    {(data && data.patientReports) && (
                        <>
                                {data.patientReports.reportAuto.jsonUrl && (
                                        <Link 
                                                onClick={() => {
                                                const url = Settings.BACKEND_URL + data.patientReports.reportAuto.jsonUrl;
                                                window.open(url, '_blank');
                                                }}>
                                                Download JSON report
                                        </Link>
                                )}

                                {data.patientReports.reportAuto.pdfUrl && (
                                        <Link 
                                                onClick={() => {
                                                const url = Settings.BACKEND_URL + data.patientReports.reportAuto.pdfUrl;
                                                window.open(url, '_blank');
                                                }}>
                                                Download (Auto) PDF report
                                        </Link>
                                )}

                                <CreateJsonReportComponent ssid={ssid} onChange={refetch} />

                                {data.patientReports.reportAuto.jsonUrl && (
                                    <>
                                        <CreatePdfReportComponent ssid={ssid} onChange={refetch} />
                                    </>
                                )}
                        </>
                    )}
                </>
            )}
        </>
    )
}
